/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useState, useEffect} from 'react'
import {KTSVG} from '../../../_metronic/helpers/components/KTSVG'
import {Link} from 'react-router-dom'
import moment from 'moment'
import {useIntl} from 'react-intl'
import {getDynamicValue} from '../functions/util'
import SVG from 'react-inlinesvg'
import {languages} from '../../../_metronic/partials/layout/header-menus/Languages'
import {useLang} from '../../../_metronic/i18n/Metronici18n'
import {courseType} from '../constants'
type Props = {
  title: String
  subtitle: String
  hour: Number
  lesson: Number
  primary?: Boolean
  primaryText?: String
  progressValue?: number
  remainingMin?: number
  imgUrl?: number
  basTarih?: number
  bitTarih?: number
  id: number
  language?: string
  allCourses?: boolean
  isCompleted?: boolean
  completedText?: string
  link?: String
  type?: string
  isCourseJoinable?: boolean
  JoinText?: string
  isCourseOnGoing?: boolean
  onGoingText?: string
}

const CourseBox: FC<Props> = ({
  title,
  subtitle,
  hour,
  lesson,
  primary,
  primaryText,
  progressValue,
  remainingMin,
  imgUrl,
  basTarih,
  bitTarih,
  id,
  language,
  allCourses,
  isCompleted,
  completedText,
  isCourseJoinable,
  JoinText,
  link,
  type,
  isCourseOnGoing,
  onGoingText,
}) => {
  // const [isStarted, setStarted] = useState(false)
  const [buttonText, setButtonText] = useState('')
  const intl = useIntl()
  const lang = useLang()
  const linkTo = link
    ? link
    : primary && primaryText
    ? allCourses
      ? '/courses/detail/' + id
      : '/my-courses/detail/' + id
    : '#'
  const isLVT = type && courseType.LVT.includes(type)
  const isF2F = type && courseType.F2F.includes(type)
  const currentLanguage = languages.find((x) => x.lang === lang)
  const isArabic = currentLanguage && currentLanguage.lang === 'ar'
  const isStartedCourse = () => {
    if (basTarih && bitTarih) {
      const basTarihDate = new Date(basTarih)
      const bitTarihDate = new Date(bitTarih)
      const now = new Date(1)
      if (basTarihDate < now) {
        // setStarted(true)
      } else {
        setButtonText(
          //intl.formatMessage({id: 'POPLEADS.AVAILABLE'}) +
          //  ' ' +
          moment(basTarihDate).format('MMM DD, YYYY') +
            ' - ' +
            moment(bitTarihDate).format('MMM DD, YYYY')
        )
      }
    }
  }

  useEffect(() => {
    isStartedCourse()
  }, [])

  const renderIcon = (type: any) => {
    if (courseType.eLearning.includes(type)) {
      return <SVG src='/media/svg/popleads/eLearning.svg' />
    } else if (courseType.LVT.includes(type)) {
      return <SVG src='/media/svg/popleads/liveVirtual.svg' />
    } else if (courseType.F2F.includes(type)) {
      return <SVG src='/media/svg/popleads/classroom.svg' />
    }
  }

  return (
    <div className='allCoursesGridItem'>
      <Link
        to={{pathname: linkTo}}
        target={link ? '_blank' : undefined}
        onClick={(event) => {
          if (type && courseType.LVT.includes(type) && !isCourseJoinable) event.preventDefault()
        }}
        className={clsx(
          type && courseType.LVT.includes(type) && !isCourseJoinable ? 'pe-none' : ''
        )}
      >
        <div
          className='allCoursesGridItemImage'
          style={{
            backgroundImage:
              'url("' +
              (imgUrl ? imgUrl : 'https://www.leoron.com/wp-content/uploads/CFM-2.jpg') +
              '")',
          }}
        >
          <div className={`allCoursesGridItemType ${isArabic ? 'end' : 'start'}`}>
            {renderIcon(type)}
            <span>{type}</span>
          </div>
        </div>
      </Link>
      <div className='allCoursesGridInfo'>
        <div className='allCoursesGridInfo_Time d-flex align-items-center'>
          <KTSVG path='/media/svg/popleads/clock.svg' className='svg-icon-1 hour-icon' />
          {hour ? (
            <span>
              {hour} {intl.formatMessage({id: 'POPLEADS.HOUR'})}
              {(Number(hour) > 1 || isNaN(+hour)) && 's'}
            </span>
          ) : (
            <span> - {intl.formatMessage({id: 'POPLEADS.HOUR'})}</span>
          )}
        </div>
        <div className='allCoursesGridInfo_Length d-flex align-items-center'>
          <KTSVG path='/media/svg/popleads/book.svg' className='svg-icon-1 hour-icon' />
          {lesson ? (
            <span>
              {lesson} {intl.formatMessage({id: 'POPLEADS.LESSON'})}
              {Number(lesson) > 1 && 's'}
            </span>
          ) : (
            <span>- {intl.formatMessage({id: 'POPLEADS.LESSON'})} </span>
          )}
        </div>
      </div>
      <div className='allCoursesGridItemSubtitle'>{subtitle}</div>
      {/* <OverlayTrigger
        key='title'
        placement='top'
        overlay={<Tooltip id='tooltip-user-name'>{title}</Tooltip>}
      >
        <div className='allCoursesGridItemTitle'>{title} </div>
      </OverlayTrigger> */}
      <Link
        to={{pathname: linkTo}}
        target={link ? '_blank' : undefined}
        onClick={(event) => {
          if (type && courseType.LVT.includes(type) && !isCourseJoinable) event.preventDefault()
        }}
        className={clsx(
          type && courseType.LVT.includes(type) && !isCourseJoinable ? 'pe-none' : ''
        )}
      >
        <div
          className={
            language === 'Arabic'
              ? 'allCoursesGridItemTitle text-end'
              : 'allCoursesGridItemTitle text-start'
          }
        >
          {title}
        </div>
      </Link>

      <div
        className={`d-flex align-items-center ${
          language === 'Arabic' ? 'flex-row-reverse' : 'flex-row'
        }`}
        style={{minHeight: '16px'}}
      >
        {(progressValue || progressValue === 0 || remainingMin) &&
          !isCompleted &&
          !isLVT &&
          !isF2F && (
            <>
              <div className='line-progress-bar-container'>
                <div
                  className='line-progress-bar'
                  style={{
                    width: `${progressValue}%`,
                  }}
                ></div>
              </div>
              <span
                className='line-progress-completed'
                style={isArabic ? {marginRight: '6px'} : {marginLeft: '6px'}}
              >
                {isArabic ? `${progressValue}% :تم الانتهاء` : `Completed ${progressValue}%`}
              </span>
            </>
          )}
      </div>
      <Link
        to={{pathname: linkTo}}
        target={link ? '_blank' : undefined}
        onClick={(event) => {
          if (type && courseType.LVT.includes(type) && !isCourseJoinable) event.preventDefault()
        }}
        className={clsx(
          type && courseType.LVT.includes(type) && !isCourseJoinable ? 'pe-none' : ''
        )}
      >
        <button
          className={clsx(
            'allCoursesGridItemButton',
            primary && 'primary',
            isCompleted && 'completed',
            isCourseJoinable && 'primary'
          )}
        >
          {isCompleted
            ? completedText
            : isCourseJoinable
            ? JoinText
            : isCourseOnGoing
            ? onGoingText
            : primary
            ? primaryText
            : buttonText}
        </button>
      </Link>
    </div>
  )
}

export {CourseBox}
