/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {ConfigDataContext} from '../../modules/context/configDataContext'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

interface MyCoursesNoCourseProps {
  isCompleted?: any
}

const MyCoursesNoCourse: FC<MyCoursesNoCourseProps> = (props: MyCoursesNoCourseProps) => {
  const intl = useIntl()

  const {configData} = useContext(ConfigDataContext)
  const imageUrl = configData ? configData.imageUrls?.noCourseImg : ''

  return (
    <div className='landing noCourse'>
      <div className='container'>
        <div className='row'>
          <div className='order-1 order-lg-0 col-lg-7 d-flex flex-column justify-content-center'>
            <h1 className='text-center text-lg-start'>
              {props.isCompleted
                ? intl.formatMessage({id: 'POPLEADS.NO_COMPLETED_COURSE'})
                : intl.formatMessage({id: 'POPLEADS.NO_COURSE'})}
            </h1>
            <p className='text-center text-lg-start'>
              {intl.formatMessage({id: 'POPLEADS.NO_COURSE_INFO'})}
            </p>
            <Link className='mx-auto mx-lg-0' to='/'>
              <button>{intl.formatMessage({id: 'POPLEADS.NO_COURSE_INFO_BUTTON'})}</button>
            </Link>
          </div>
          <div className='order-0 order-lg-1 col-lg-5 my-6 d-flex justify-content-center'>
            <img
              id='illusRight'
              alt=''
              src={imageUrl ? imageUrl : toAbsoluteUrl('/media/fallback/noCourse.png')}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export {MyCoursesNoCourse}
