/* eslint-disable react-hooks/exhaustive-deps */
import React, {Suspense, useState, useEffect, useContext} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import AuthInit from './modules/auth/redux/AuthInit'
import {ConfigDataContext} from './modules/context/configDataContext'
import {post} from './popleads/networking/requestService'
import {Routes} from './routing/Routes'
import {
  addBackgrounds,
  addFontFaces,
  applyColors,
  changeSeoValues,
} from '../app/popleads/functions/applyConfigValues'

type Props = {
  basename: string
}

const App: React.FC<Props> = ({basename}) => {
  const [isLoading, setIsLoading] = useState(true)
  const {configData, setConfigData} = useContext(ConfigDataContext)
  const root = document.documentElement

  const getConfigData = () => {
    const requestObj = {
      fn: 'getSiteConfig',
      url: window.location.hostname, //'poptalent-react-test.popleads.com'   //localhost
    }
    post(requestObj)
      .then(({data: {siteConfig}}) => {
        try {
          const obj = JSON.parse(
            siteConfig.find((config: any) => config?.configKey === 'lmsConfig')?.configValue
          )
          // console.log(obj)
          changeSeoValues(obj)
          addFontFaces(obj)
          setConfigData(obj)
        } catch (e) {
          console.log(e)
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    // LOAD FROM REMOTE
    getConfigData()

    // LOAD FROM PUBLIC (FOR DEVELOPMENT ONLY)
    // fetch('/siteConfig_SRC.json')
    //   .then((res) => res.json())
    //   .then((data) => {
    //     changeSeoValues(data)
    //     applyColors(root, data)
    //     addBackgrounds(root,data)
    //     addFontFaces(data)
    //     setConfigData(data)
    //     setIsLoading(false)
    //   })
    //   .catch(() => setIsLoading(false))
  }, [])

  // apply colors that comes from config data
  applyColors(root, configData)
  addBackgrounds(root, configData)

  // set global font families
  let tempArr = []
  let tempArr2 = []
  if (configData?.fontFamilies?.en) {
    tempArr.push(configData.fontFamilies.en)
  }
  if (configData?.fontFamilies?.ar) {
    tempArr.push(configData.fontFamilies.ar)
  }

  if (configData?.headingFontFamilies?.en) {
    tempArr2.push(configData.headingFontFamilies.en)
  }
  if (configData?.headingFontFamilies?.ar) {
    tempArr2.push(configData.headingFontFamilies.ar)
  }
  tempArr.push('Poppins') //falback font
  const fontFamilies = tempArr.join()
  const headingFontFamilies = tempArr2.join()
  root?.style.setProperty('--font-family', fontFamilies)
  root?.style.setProperty('--heading-font-family', headingFontFamilies)

  return (
    <>
      {!isLoading && (
        <Suspense fallback={<LayoutSplashScreen />}>
          <BrowserRouter basename={basename}>
            <I18nProvider>
              <LayoutProvider>
                <AuthInit>
                  <Routes />
                </AuthInit>
              </LayoutProvider>
            </I18nProvider>
          </BrowserRouter>
        </Suspense>
      )}
    </>
  )
}

export {App}
