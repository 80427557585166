import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../_metronic/helpers/components/KTSVG'
import {CourseBox} from '../components/courseBox'
import {getDynamicValue} from '../functions/util'
import {post} from '../networking/requestService'
import {AllCoursesNoCourse} from './AllCoursesNoCourse'
import {courseType} from '../constants'
interface AllCoursesProps {
  campaignList: any
  sortAndOrder: any
}

const AllCourses: FC<AllCoursesProps> = (props: AllCoursesProps) => {
  const intl = useIntl()
  const [courses, setCourses] = useState<any>([])
  const [myCourses, setMyCourses] = useState<any>(null)
  const [activeTab, setActiveTab] = useState(2)
  useEffect(() => {
    if (activeTab === 2) {
      const requestObj = {
        fn: 'getProfileCampaigns',
      }
      post(requestObj).then(({data: {campaignCurrentList, campaignHistoryList}}) => {
        setMyCourses({campaignCurrentList, campaignHistoryList})
      })
    }
  }, [courses])

  const isCompleted = (course: any) => {
    if (myCourses) {
      const history = myCourses.campaignHistoryList
      for (let i = 0; i < history.length; i++) {
        if (history[i].id === course.id) return true
      }
    }
    return false
  }

  const isStarted = (course: any) => {
    if (myCourses) {
      const current = myCourses.campaignCurrentList
      for (let i = 0; i < current.length; i++) {
        if (current[i].id === course.id) return true
      }
    }
    return false
  }
  const isCourseJoinable = (course: any) => {
    const today = Date.now()
    if (
      today > course.basTarih &&
      today < course.bitTarih &&
      courseType.LVT.includes(course.type)
    ) {
      return true
    }
  }

  const isCourseOnGoing = (course: any) => {
    const today = Date.now()
    if (
      today > course.basTarih &&
      today < course.bitTarih &&
      courseType.F2F.includes(course.type)
    ) {
      return true
    }
  }

  const handleTabClick = (tabNumber: number) => {
    setActiveTab(tabNumber)
  }

  useEffect(() => {
    setCourses(props.campaignList)
  }, [props.campaignList])

  return (
    <div>
      <div className='row allCourses'>
        <div className='section w-100 '>
          <div className='container  d-flex justify-content-between'>
            <div className='title d-flex align-items-center'>
              <KTSVG path='/media/svg/popleads/play.svg' className='svg-icon-1 play' />
              {intl.formatMessage({id: 'POPLEADS.E-LEARNING_COURSE'})}
            </div>
            <select
              className='d-none d-sm-block sortSelect'
              onChange={(change: any) => {
                // console.log("change: ", change);
                change = change.target.value
                if (change === 'startDate') {
                  props.sortAndOrder('basTarih', 'asc')
                } else if (change === 'asc') {
                  props.sortAndOrder('ad', 'asc')
                } else if (change === 'desc') {
                  props.sortAndOrder('ad', 'desc')
                }
              }}
            >
              <option value='startDate'>{intl.formatMessage({id: 'POPLEADS.START_DATE'})}</option>
              <option value='asc'>{intl.formatMessage({id: 'POPLEADS.A-Z_SORTING'})}</option>
              <option value='desc'>{intl.formatMessage({id: 'POPLEADS.Z-A_SORTING'})}</option>
            </select>
          </div>
        </div>
        <select
          className='d-block d-sm-none mt-5 sortSelectMobile'
          onChange={(change: any) => {
            // console.log("change: ", change);
            change = change.target.value
            if (change === 'startDate') {
              props.sortAndOrder('basTarih', 'asc')
            } else if (change === 'asc') {
              props.sortAndOrder('ad', 'asc')
            } else if (change === 'desc') {
              props.sortAndOrder('ad', 'desc')
            }
          }}
        >
          <option value='startDate'>{intl.formatMessage({id: 'POPLEADS.START_DATE'})}</option>
          <option value='asc'>{intl.formatMessage({id: 'POPLEADS.A-Z_SORTING'})}</option>
          <option value='desc'>{intl.formatMessage({id: 'POPLEADS.Z-A_SORTING'})}</option>
        </select>
        <div className='courseLanguageContainer'>
          <button
            onClick={() => handleTabClick(1)}
            className={`courseLanguage ${activeTab === 1 ? 'selected' : ''}`}
          >
            <span>English</span>
          </button>
          <button
            onClick={() => handleTabClick(2)}
            className={`courseLanguage ${activeTab === 2 ? 'selected' : ''}`}
          >
            <span>Arabic</span>
          </button>
        </div>
        {courses.length && activeTab === 2 ? (
          <div className='allCoursesGrid'>
            {courses.map((course: any) => (
              <CourseBox
                key={course.id}
                title={course.ad}
                type={course.type}
                subtitle={getDynamicValue(course.columnsList, 'Short Description')}
                hour={getDynamicValue(course.columnsList, 'Video Hours')}
                lesson={getDynamicValue(course.columnsList, 'Number of Lectures')}
                language={getDynamicValue(course.columnsList, 'Language')}
                primary={
                  !courseType.LVT.includes(course.type) &&
                  ((course.basTarih !== null && new Date() > new Date(course.basTarih)) ||
                    course.basTarih === null)
                }
                primaryText={
                  !courseType.LVT.includes(course.type)
                    ? isStarted(course)
                      ? intl.formatMessage({id: 'POPLEADS_RESUME_COURSE'})
                      : intl.formatMessage({id: 'POPLEADS.GO_TO_COURSE'})
                    : undefined
                }
                imgUrl={course.imgURL}
                basTarih={course.basTarih}
                bitTarih={course.bitTarih}
                id={course.id}
                allCourses={true}
                isCompleted={isCompleted(course)}
                isCourseJoinable={isCourseJoinable(course)}
                JoinText={intl.formatMessage({id: 'POPLEADS.JOIN_COURSE'})}
                isCourseOnGoing={isCourseOnGoing(course)}
                onGoingText={intl.formatMessage({id: 'POPLEADS.ON_GOING'})}
                completedText={intl.formatMessage({id: 'POPLEADS_COURSE_COMPLETED'})}
                link={course.link}
              />
            ))}
          </div>
        ) : !courses.length && activeTab === 2 ? (
          <div className='allCoursesNoCourse'>
            <AllCoursesNoCourse />
          </div>
        ) : (
          activeTab === 1 && (
            <div className='allCoursesGrid'>
              <div className='comingSoonCourseCard'>
                <div className='comingSoonCourseCardImage'>
                  <img src='/media/fallback/EdX.png' alt='Coming Soon' width={285} />
                  <div className='comingSoonCourseCardLogo'>
                    <img src='/media/logos/EdX_logo.png' alt='EdX' width={62}/>
                  </div>
                </div>
                <div className='comingSoonCourseCardLogo'>
                  <p>EdX Library Coming Soon</p>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  )
}

export {AllCourses}
