/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, Fragment, useContext, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Accordion, Button, Card} from 'react-bootstrap-v5'
import {KTSVG} from '../../../_metronic/helpers/components/KTSVG'
import {post} from '../networking/requestService'
import {UserModel} from '../../modules/auth/models/UserModel'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import {Error} from '../partials/error'
import * as auth from '../../modules/auth/redux/AuthRedux'
import {getUserByToken} from '../../modules/auth/redux/AuthCRUD'
import {Link} from 'react-router-dom'
import {getDynamicValue} from '../functions/util'
import {FinishedCourse} from './finishedCourse'
import {ConfigDataContext} from '../../modules/context/configDataContext'
import SVG from 'react-inlinesvg'
import {courseType} from '../constants'
type Props = {
  course: any
  getCampaign: () => void
  setDisplayNavBar: any
}

const CoursePreview: FC<Props> = (props: Props) => {
  const {course, getCampaign} = props
  const intl = useIntl()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const dispatch = useDispatch()
  const [error, setError] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const [iframeLink, setIframeLink] = useState<any>(null)
  const [lmsData, setLmsData] = useState<any>(null)
  const [frameWidth, setFrameWidth] = useState<any>(null)
  const [frameHeight, setFrameHeight] = useState<any>(null)
  const [videoType, setVideoType] = useState<any>(null)
  const [dynamicSchema, setDynamicSchema] = useState<any>([])
  const [lastWatched, setLastWatched] = useState<any>(-1)
  const [isNewlyEnrolled, setIsNewlyEnrolled] = useState(false)
  const [isCompleted, setCompleted] = useState<any>(false)
  const [checkCompletion, setChechCompletion] = useState<boolean>(false)
  const [courseScore, setCourseScore] = useState<any>(null)
  const [nowCompleted, setNowCompleted] = useState<any>(false)
  const [subjectLanguage, setSubjectLanguage] = useState('EN')
  const [courseCountry, setCourseCountry] = useState('')
  const [courseCity, setCourseCity] = useState('')

  useEffect(() => {
    setChechCompletion(true)
    const requestObj = {
      fn: 'getProfileCampaigns',
    }
    post(requestObj)
      .then(({data: {campaignHistoryList}}) => {
        const history = campaignHistoryList
        for (let i = 0; i < history.length; i++) {
          if (history[i].id === course.campaign.id) setCompleted(true)
        }
      })
      .finally(() => setChechCompletion(false))
  }, [course])

  const getDynamicSchema = async () => {
    const queryParams = {
      fn: 'getNewColumns',
      moduleName: 'Training Request',
      getOrder: 1,
    }
    let data = await post(queryParams)
    setDynamicSchema(data.data.columns)
  }

  const isF2F = courseType.F2F.includes(course?.campaign.type)
  const isLVT = courseType.LVT.includes(course?.campaign.type)

  const onMessageFromIFrame = (event: any) => {
    // console.log(event);
    if (event.data && event.data.fn) {
      // console.log("messageData = ", event.data);
      if (event.data.fn === 'lmsCourseContent') {
        let screenWidth = window.innerWidth > 0 ? window.innerWidth : window.screen.width
        let height = parseInt(event.data.height)
        let type = event.data.type
        setVideoType(type)
        if (type === 'video') {
          setFrameHeight(screenWidth > 740 ? 460 : '100%')
        } else if (type === 'iframe') {
          setFrameHeight(height)
        }
        if (event.data.resume_data) {
          const nextUnitUrl = event.data.resume_data.trim()
          if (nextUnitUrl) {
            setLmsData((prevObj: any) => {
              if (!prevObj) return prevObj
              let obj = {...prevObj}
              for (const unit of obj.courseBlockUnits) {
                for (const section of unit.section_units) {
                  if (section.lms_web_url === nextUnitUrl) {
                    section.isActive = true
                  }
                }
              }
              return obj
            })
          }
        }
      } else if (event.data.fn === 'lmsResumeCourseUpdate') {
        // user finished a unit
        setLmsData((prevObj: any) => {
          if (!prevObj) return prevObj
          let obj = {...prevObj}
          let toActiveNext = false
          for (const unit of obj.courseBlockUnits) {
            for (const section of unit.section_units) {
              if (section.isActive) {
                section.isActive = false
                section.done = true
                toActiveNext = true
              } else if (toActiveNext) {
                section.isActive = true
                toActiveNext = false
                break
              }
            }
          }
          return obj
        })
      } else if (event.data.fn === 'lmsCourseIsDone') {
        setNowCompleted(true)
        getUpdatedLoggedInUser()

        /*if (event.data.passed && event.data.downloadable === 'downloadable') {
                    getCampaign();
                }*/
      } else if (event.data.fn === 'lmsCloseIframe') {
        getUpdatedLoggedInUser()
        setIframeLink(null)
      }
    }
  }

  const getUpdatedLoggedInUser = async () => {
    const data = await getUserByToken()
    const user = data.data.user
    dispatch(auth.actions.fulfillUser(user))

    for (var i = 0; i < user.profile.etiket.length; i++) {
      const obj = user.profile.etiket[i]

      if (
        obj.campaignId === course.campaign.id &&
        // obj.isQualifyForCertificate === 1 &&
        obj.lmsStatus === 'Completed' &&
        obj.completionDate
      ) {
        if (obj.isQualifyForCertificate === 1) props.setDisplayNavBar(true)
        getCampaign()
        setCourseScore(obj.lmsScore)
      }
    }
  }
  const date = new Date(course.campaign?.basTarih)
  const month = date.toLocaleString('default', {month: 'long'})
  const year = date.getFullYear()
  useEffect(() => {
    if (course.campaign.lmsUnits) {
      const data = JSON.parse(course.campaign.lmsUnits)
      //setLmsData(JSON.parse(course.campaign.lmsUnits));
      setLmsData(data)
    }
    isLastWatched()
    getDynamicSchema()
    window.addEventListener('message', onMessageFromIFrame)

    const courseLanguage = getDynamicValue(course?.campaign?.columnsList, 'Language')
    const country = getDynamicValue(course?.campaign?.columnsList, 'Country')
    const city = getDynamicValue(course?.campaign?.columnsList, 'City')
    if (!!country) setCourseCountry(country)
    if (!!city) setCourseCity(city)

    setSubjectLanguage(courseLanguage === 'Arabic' ? 'AR' : 'EN')
  }, [])

  useEffect(() => {
    setIframeLink(null)
    if (course.campaign.lmsUnits) {
      const data = JSON.parse(course.campaign.lmsUnits)
      //setLmsData(JSON.parse(course.campaign.lmsUnits));
      setLmsData(data)

      // console.log(course);
      // console.log(isNewlyEnrolled);
      if (course.isEnrolled && isNewlyEnrolled) {
        sectionClick(data.courseBlockUnits[0].section_units[0])
        setIsNewlyEnrolled(false)
      }
    }
  }, [course])

  useEffect(() => {
    isLastWatched()
  }, [lmsData])

  const enrollToCourse = () => {
    setLoading(true)
    if (!dynamicSchema || dynamicSchema.length < 1) {
      getDynamicSchema()
      setLoading(false)
      return
    }
    // console.log(dynamicSchema);
    let columnsKeys = []
    let columnsVals = []
    for (let schema of dynamicSchema) {
      if (schema.fieldName === 'User ID') {
        columnsKeys.push(schema.schemaId)
        columnsVals.push(user.profile.id)
      } else if (schema.fieldName === 'Training ID') {
        columnsKeys.push(schema.schemaId)
        columnsVals.push(course.campaign.id)
      }
    }
    // console.log(columnsKeys);
    // console.log(columnsVals);
    const queryParams = {
      fn: 'activityInsert',
      subject: 'Training Request for ' + user.profile.ad + ' ' + user.profile.soyad,
      dueDate: new Date().getTime(),
      startDate: new Date().getTime(),
      status: 'In-Progress',
      type: 'Training Request',
      important: 0,
      invoiceRequisition: null,
      purchaseRequisition: null,
      actionPlan: 0,
      isFromInvoiceWithInquiry: 0,
      columnsVals,
      columnsKeys,
    }

    post(queryParams)
      .then(() => {
        getUser()
        setLoading(false)
        setIsNewlyEnrolled(true)
      })
      .catch((err) => {
        setError({text: err.response.data.text, code: err.response.status})
        setLoading(false)
      })
  }

  const getUser = async () => {
    const data = await getUserByToken()
    dispatch(auth.actions.fulfillUser(data.data.user))
  }

  const setActiveSection = (choosenSection: any) => {
    setLmsData((prevObj: any) => {
      if (!prevObj) return prevObj
      let obj = {...prevObj}
      if (choosenSection) {
        for (const unit of obj.courseBlockUnits) {
          for (const section of unit.section_units) {
            if (section.lms_web_url === choosenSection.lms_web_url) {
              section['isActive'] = true
            } else {
              section['isActive'] = false
            }
          }
        }
      } else {
        obj.courseBlockUnits[0].section_units[0].isActive = true
      }
      return obj
    })
  }

  const sectionClick = (section: any) => {
    if (!section) {
      section = {
        lms_web_url: lmsData.resume_course_url
          ? lmsData.resume_course_url
          : lmsData.courseBlockUnits[0].section_units[0].lms_web_url,
      }
    }
    setActiveSection(section)
    const siteURL = user.company.companyConfig.lmsCompanyURL
    const url =
      siteURL +
      '/poptalent/login_redirect.html?token=' +
      encodeURIComponent(user.lmsToken) +
      '&url=' +
      encodeURIComponent(section.lms_web_url)
    // if (isRedVector()) window.open(url, "_blank");
    // else setIframeLink(url);
    setIframeLink(url)
  }

  // const isRedVector = () => {
  //     if (lmsData && lmsData.courseBlockUnits && lmsData.courseBlockUnits.length === 1 &&
  //         lmsData.courseBlockUnits[0].section_units && lmsData.courseBlockUnits[0].section_units.length === 1) { // means RedVector course
  //         return true;
  //     }
  //     return false;
  // }

  const isLastWatched = () => {
    let lastWatched = -1
    if (lmsData) lastWatched = lmsData.courseBlockUnits.length - 1

    if (lmsData)
      for (var i = 0; i < lmsData.courseBlockUnits.length; i++) {
        for (const unit of lmsData.courseBlockUnits[i].section_units) {
          if (unit.done === false) {
            lastWatched = i
            setLastWatched(lastWatched)
            return false
          }
        }
      }
    setLastWatched(lastWatched)
  }

  const setNextEnable = () => {
    if (lmsData) {
      let tempData = JSON.parse(JSON.stringify(lmsData))
      let lastSection = undefined
      for (let i = 0; i < tempData.courseBlockUnits.length; i++) {
        for (let j = 0; j < tempData.courseBlockUnits[i].section_units.length; j++) {
          const unit = tempData.courseBlockUnits[i]
          const section = tempData.courseBlockUnits[i].section_units[j]
          if (!unit.done && !section.done && lastSection) {
            if (lastSection.done) {
              section.forceEnable = true
            }
          }
          lastSection = section
        }
      }
      return tempData
    } else return null
  }

  const css = `
    @media (max-width: 1440px) {
        .container {
            width: 100% !important;
            max-width:100% !important; 
            padding:0;
            margin:0;
        }

        .myCourse_overview{
            margin:0 20px;
        }

        .navbarMyCourses {
            padding: 0 30px;
        }
    }

    @media (min-width: 1200px) and (max-width: 1440px) {
        .header, .header-fixed[data-kt-sticky-header=on] .header, .pageContainer .certificatesTab {
            padding: 0 60px;
        }

        .myCourse_overview  {
            padding: 0 30.25px;
        }

        .pageContainer .coursePreview .coursePreview_Actions .coursePreview_ActionsInfo {
            padding: 0 60px 30px;
        }
    }

    @media (max-width: 1199px) {
        .header, .header-fixed[data-kt-sticky-header=on] .header, .pageContainer .certificatesTab {
            padding: 0 20px;
        }

        .myCourse_overview, .navbarMyCourses  {
            padding: 0;
        }

        .navbarMyCourses .container .navbarMyCourses_Item {
            padding: 0 5px;
        }

        .pageContainer .coursePreview .coursePreview_Actions .coursePreview_ActionsInfo {
            padding: 0 20px 30px;
        }
    }

    @media (max-width: 1024px) {
        .navbarMyCourses .container .navbarMyCourses_Item, .pageContainer .certificatesTab {
            padding: 0 15px;
        }
    }
`

  const changedLMSData = setNextEnable()

  const onSubjectLanguageChange = () => {
    setSubjectLanguage(subjectLanguage === 'EN' ? 'AR' : 'EN')

    const requestObj = {
      fn: 'changeEnrollAndUnenrollCourse',
      campaignId: course.campaign.id,
    }
    post(requestObj).then(({data: {campaignId}}) => {
      const url = `${window.location.protocol}//${window.location.host}/my-courses/detail/${campaignId}`
      window.open(url, '_self')
    })
  }
  console.log('course', course)
  const {configData} = useContext(ConfigDataContext)
  const showLangSwitch =
    configData?.company === 'Olayan'
      ? !!(
          getDynamicValue(course?.campaign?.columnsList, 'ChangeCoursePairId') &&
          !checkCompletion &&
          !isCompleted
        )
      : !!getDynamicValue(course?.campaign?.columnsList, 'ChangeCoursePairId')

  return (
    <div className='courseContent'>
      <div className='container'>
        <style>{css}</style>
        {error ? (
          <Error text={error.text} code={error.code} />
        ) : (
          <div
            className='coursePreview'
            style={{
              height: iframeLink ? (videoType !== 'video' && frameHeight ? frameHeight : '') : '',
            }}
          >
            <div
              className={
                'coursePreview_Actions ' +
                (iframeLink
                  ? videoType === 'video'
                    ? 'iframe-active-video'
                    : 'iframe-active-iframe'
                  : '')
              }
              style={{
                minHeight: !iframeLink ? '512px' : '',
                backgroundImage:
                  "url('" +
                  (course.campaign.imgURL
                    ? course.campaign.imgURL
                    : "https://camo.envatousercontent.com/e73509df61c8b8a84fb65262f218a26757068cf4/68747470733a2f2f696d616765732e706578656c732e636f6d2f70686f746f732f3734363338362f706578656c732d70686f746f2d3734363338362e6a7065673f6175746f3d636f6d70726573732663733d74696e7973726762266470723d3226683d37353026773d31323630')"),
              }}
            >
              {iframeLink && (
                <iframe
                  width={frameWidth ? frameWidth : '100%'}
                  height={frameHeight ? frameHeight : '100%'}
                  src={iframeLink}
                  allowFullScreen={true}
                  allow='autoplay; fullscreen'
                  sandbox='allow-same-origin allow-scripts allow-presentation allow-popups allow-forms'
                  title={course.campaign.ad}
                  id='iframe'
                ></iframe>
              )}
              {!iframeLink && (
                <>
                  {nowCompleted && courseScore !== null ? (
                    <FinishedCourse
                      point={courseScore}
                      campaign={course.campaign}
                      sectionClick={(btn) => {
                        if (btn === 'take-quiz-again') {
                          sectionClick(
                            changedLMSData.courseBlockUnits[
                              changedLMSData.courseBlockUnits.length - 1
                            ].section_units[
                              changedLMSData.courseBlockUnits[
                                changedLMSData.courseBlockUnits.length - 1
                              ].section_units.length - 1
                            ]
                          )
                        }
                        if (btn === 'play-again') {
                          sectionClick(changedLMSData.courseBlockUnits[0].section_units[0])
                        }
                        setCourseScore(null)
                        setNowCompleted(false)
                      }}
                    />
                  ) : (
                    <div className='coursePreview_ActionsCover'>
                      <div className='coursePreview_ActionsInfo'>
                        <h1>{course.campaign.ad}</h1>
                        <div className='coursePreview_ActionsButtons'>
                          {isF2F || isLVT ? (
                            isF2F ? (
                              <>
                                <button className='join'>
                                  {intl.formatMessage({id: 'POPLEADS.ON_GOING'})}
                                </button>
                                <div className='info_container'>
                                  <div className='d-flex align-items-center info'>
                                    <i
                                      className='flaticon2-location'
                                      style={{fontSize: '1.5rem'}}
                                    ></i>
                                    {courseCity && courseCountry ? (
                                      <h4>{`${courseCity}, ${courseCountry}`}</h4>
                                    ) : (
                                      <h4>-</h4>
                                    )}
                                  </div>
                                  <div className='d-flex align-items-center info'>
                                    <SVG src='/media/svg/popleads/clock.svg' width={'1.5rem'} />
                                    <h4>{`${month}, ${year}`}</h4>
                                  </div>
                                </div>
                              </>
                            ) : isLVT ? (
                              <>
                                <button className='play'>
                                  {intl.formatMessage({id: 'POPLEADS.JOIN_COURSE'})}
                                </button>
                                <div className='info_container'>
                                  <div className='d-flex align-items-center info'>
                                    <SVG src='/media/svg/popleads/video.svg' width={'1.5rem'} />
                                    <h4>{course.campaign?.contentType}</h4>
                                  </div>
                                  <div className='d-flex align-items-center info'>
                                    <SVG src='/media/svg/popleads/clock.svg' width={'1.5rem'} />
                                    <h4>{`${month}, ${year}`}</h4>
                                  </div>
                                </div>
                              </>
                            ) : null
                          ) : course.isEnrolled ? (
                            isCompleted ? (
                              <>
                                <div className='d-flex align-items-center'>
                                  <div className='completedTag'>
                                    {intl.formatMessage({id: 'POPLEADS_COURSE_COMPLETED'})}
                                  </div>
                                </div>
                                <button onClick={() => sectionClick(null)} className='play-again'>
                                  <KTSVG
                                    path='/media/svg/popleads/playCircle.svg'
                                    className='svg-icon-1'
                                  />
                                  {intl.formatMessage({id: 'POPLEADS.PLAY_AGAIN'})}
                                </button>
                              </>
                            ) : (
                              <button onClick={() => sectionClick(null)} className='play'>
                                <KTSVG
                                  path='/media/svg/popleads/playCircle.svg'
                                  className='svg-icon-1'
                                />
                                {intl.formatMessage({id: 'POPLEADS.PLAY'})}
                              </button>
                            )
                          ) : !course.campaign.link ? (
                            <button
                              disabled={loading}
                              onClick={() => enrollToCourse()}
                              className='play'
                            >
                              {loading && <div className='spinner-border' />}
                              {!loading && (
                                <p style={{flex: 1}}>
                                  {' '}
                                  {intl.formatMessage({id: 'POPLEADS.ENROLL'})}
                                </p>
                              )}
                            </button>
                          ) : (
                            <Link
                              to={{pathname: course.campaign.link}}
                              target={course.campaign.link ? '_blank' : undefined}
                            >
                              <button disabled={loading} className='play'>
                                {loading && <div className='spinner-border' />}
                                {!loading && (
                                  <p style={{flex: 1}}>
                                    {' '}
                                    {intl.formatMessage({id: 'POPLEADS.ACCESS'})}
                                  </p>
                                )}
                              </button>
                            </Link>
                          )}
                          {/*<button className='addFavorite'>
                                      <KTSVG
                                          path='/media/svg/popleads/heart.svg'
                                          className='svg-icon-1'
                                      />
                                      Add Favorites
                                  </button>*/}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            {!(isF2F || isLVT) && (
              <div className='coursePreview_Subjects'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='title'> {intl.formatMessage({id: 'POPLEADS.CONTENT'})}</div>
                  {showLangSwitch && (
                    <label className='languageSwitch mx-10'>
                      <input
                        type='checkbox'
                        onChange={onSubjectLanguageChange}
                        checked={subjectLanguage === 'AR'}
                      />
                      <div className='slider' data-language={subjectLanguage} />
                    </label>
                  )}
                </div>
                <Accordion
                  className='coursePreview_Items accordion-toggle-arrow'
                  activeKey={lastWatched.toString()}
                >
                  {!changedLMSData && (
                    <h3 onClick={getCampaign} className='coursePreview_noContent'>
                      {intl.formatMessage({id: 'POPLEADS.NO_CONTENT'})}
                    </h3>
                  )}
                  {changedLMSData &&
                    changedLMSData.courseBlockUnits &&
                    changedLMSData.courseBlockUnits.map((unit: any, unitIndex: number) => {
                      return (
                        <Fragment key={`course__preview__${unitIndex}`}>
                          <Card>
                            <Card.Header>
                              <Accordion.Toggle
                                as={Button}
                                style={{direction: subjectLanguage === 'AR' ? 'rtl' : 'ltr'}}
                                onClick={() => {
                                  if (lastWatched === unitIndex) setLastWatched(-1)
                                  else setLastWatched(unitIndex)
                                }}
                                variant='link'
                                eventKey={unitIndex + ''}
                                className={
                                  (subjectLanguage === 'AR' ? 'rtl' : 'ltr') +
                                  ' d-flex align-items-center flex-row w-100 withArrow' +
                                  (lastWatched === unitIndex ? ' active-unitindex ' : '')
                                }
                              >
                                {unit.section_name}
                              </Accordion.Toggle>
                            </Card.Header>

                            {unit.section_units &&
                              unit.section_units.map((section: any, sectionIndex: number) => {
                                return (
                                  <Accordion.Collapse
                                    eventKey={unitIndex + ''}
                                    key={`preview__section__${sectionIndex}`}
                                  >
                                    <Card.Body
                                      className={
                                        'd-flex flex-column subjectsAccordion py-0' +
                                        (section.isActive ? ' active-section' : '')
                                      }
                                    >
                                      <button
                                        disabled={
                                          !course.isEnrolled ||
                                          (!section.done &&
                                            !section.forceEnable &&
                                            !(unitIndex === 0 && sectionIndex === 0))
                                        }
                                        onClick={() => sectionClick(section)}
                                        style={{
                                          direction: subjectLanguage === 'AR' ? 'rtl' : 'ltr',
                                        }}
                                      >
                                        <span className='left-icon'>
                                          {course.isEnrolled && section.done && (
                                            <KTSVG
                                              path='/media/svg/popleads/stcpay_course_complete.svg'
                                              className='svg-icon-1 tickIcon'
                                            />
                                          )}
                                        </span>
                                        <span
                                          className='unit-text'
                                          style={{
                                            textAlign: subjectLanguage === 'AR' ? 'right' : 'left',
                                          }}
                                        >
                                          {section.display_name}
                                        </span>

                                        {course.isEnrolled && !section.done && (
                                          <KTSVG
                                            path='/media/svg/popleads/playCircle.svg'
                                            className={`svg-icon-1 play-unit ${
                                              subjectLanguage === 'AR' ? 'rtl' : 'ltr'
                                            }`}
                                          />
                                        )}
                                      </button>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                )
                              })}
                          </Card>
                        </Fragment>
                      )
                    })}
                </Accordion>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export {CoursePreview}
